import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Link from "gatsby-link";
import { StaticImage } from "gatsby-plugin-image";

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <a
      href="https://amol.sarva.co/new-directions-that-are-catching-my-eye/"
      id="banner"
    >
      How Popular Change came about
    </a>
    <header className="header">
      <h3>
        <a href="https://popularchange.com/">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10.9969 16.8033C14.3214 16.3204 16.875 13.4584 16.875 10C16.875 6.5416 14.3214 3.67963 10.9969 3.19674C10.7004 3.15368 10.5521 3.13215 10.3988 3.19165C10.2758 3.23941 10.1459 3.35182 10.0809 3.46672C10 3.60986 10 3.78158 10 4.125V15.875C10 16.2184 10 16.3901 10.0809 16.5333C10.1459 16.6482 10.2758 16.7606 10.3988 16.8084C10.5521 16.8679 10.7004 16.8463 10.9969 16.8033Z"
              fill="#333333"
            ></path>
          </svg>
          <span>Diagram</span>
        </a>
      </h3>
      <ul className="nav">
        <li>
          <Link to="#aikito">Aikito</Link>
        </li>
        <li>
          <Link to="#cornerup">CornerUP</Link>
        </li>
        <li>
          <Link to="#dinara">Dinara</Link>
        </li>
        <li>
          <Link to="#ratio21">Ratio21</Link>
        </li>
        <li>
          <Link to="#lifex">LifeX</Link>
        </li>
      </ul>

      <h3 className="offset">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10.9969 16.8033C14.3214 16.3204 16.875 13.4584 16.875 10C16.875 6.5416 14.3214 3.67963 10.9969 3.19674C10.7004 3.15368 10.5521 3.13215 10.3988 3.19165C10.2758 3.23941 10.1459 3.35182 10.0809 3.46672C10 3.60986 10 3.78158 10 4.125V15.875C10 16.2184 10 16.3901 10.0809 16.5333C10.1459 16.6482 10.2758 16.7606 10.3988 16.8084C10.5521 16.8679 10.7004 16.8463 10.9969 16.8033Z"
            fill="#fff"
          ></path>
        </svg>
      </h3>
    </header>

    <section id="apps" className="inner">
      <div id="hero" className="app">
        <div className="content">
          <div className="text">
            <h2>Popular Change</h2>
            <p>Take a chance on making something people like.</p>
          </div>
        </div>
      </div>

      <div id="aikito" className="app previewed">
        <div className="flex">
          <div className="content">
            <div className="icon">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.0912 2.08893C12.6382 2.28088 14.173 2.77929 15.6079 3.60772C21.3474 6.92142 23.3139 14.2605 20.0002 20C16.6865 14.2605 9.34738 12.294 3.60787 15.6077C2.17508 16.4349 0.977418 17.513 0.0383301 18.7544C0.48771 11.4411 4.86728 5.1907 11.0912 2.08893Z"
                  fill="#FFD600"
                ></path>
                <path
                  d="M0.0381557 18.7544C0.977243 17.513 2.17491 16.4349 3.6077 15.6077C9.34721 12.2939 16.6863 14.2604 20 20C13.3726 20 8 25.3725 8 32C8 33.6554 8.3352 35.2325 8.94142 36.6671C3.55202 33.0841 0 26.9568 0 20C0 19.5816 0.0128428 19.1663 0.0381557 18.7544Z"
                  fill="#1BC47D"
                ></path>
                <path
                  d="M8.94142 36.6672C8.3352 35.2325 8 33.6554 8 32C8 25.3726 13.3726 20 20 20C16.6863 25.7395 18.6528 33.0786 24.3923 36.3923C25.8272 37.2207 27.362 37.7191 28.9089 37.9111C26.2263 39.248 23.2009 40 20 40C15.9112 40 12.109 38.773 8.94142 36.6672Z"
                  fill="#18A0FB"
                ></path>
                <path
                  d="M28.909 37.9111C27.3621 37.7191 25.8273 37.2207 24.3924 36.3923C18.6529 33.0786 16.6864 25.7395 20.0001 20C23.3138 25.7395 30.6529 27.706 36.3924 24.3923C37.8252 23.5651 39.0228 22.487 39.9619 21.2456C39.5125 28.5589 35.133 34.8093 28.909 37.9111Z"
                  fill="#7B61FF"
                ></path>
                <path
                  d="M39.9618 21.2456C39.0228 22.487 37.8251 23.5651 36.3923 24.3923C30.6528 27.706 23.3137 25.7395 20 20C26.6274 20 32 14.6274 32 7.99998C32 6.34458 31.6648 4.76747 31.0586 3.33282C36.448 6.91583 40 13.0431 40 20C40 20.4183 39.9872 20.8336 39.9618 21.2456Z"
                  fill="#F24822"
                ></path>
                <path
                  d="M31.0586 3.33284C31.6648 4.76749 32 6.3446 32 8C32 14.6274 26.6274 20 20 20C23.3137 14.2605 21.3472 6.9214 15.6077 3.6077C14.1728 2.77927 12.638 2.28086 11.0911 2.08891C13.7737 0.751976 16.799 0 20 0C24.0888 0 27.891 1.22699 31.0586 3.33284Z"
                  fill="#F97F0F"
                ></path>
              </svg>
            </div>
            <div className="text">
              <h2>Aikito</h2>
              <p>Location finance to build with purpose</p>
              <a href="https://aikito.co/">Learn more</a>
            </div>
          </div>
        </div>

        <div className="flex preview">
          <StaticImage
            className="plugin"
            src="../images/automator.svg"
            alt=""
          />
        </div>
      </div>

      <div id="cornerup" className="app previewed">
        <div className="flex">
          <div className="content">
            <div className="icon">
              <svg
                width="44"
                height="44"
                viewBox="0 0 44 44"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M29.92 25.52H18.48V14.08C18.48 7.76187 23.6019 2.64 29.92 2.64C36.2382 2.64 41.36 7.76186 41.36 14.08C41.36 20.3981 36.2382 25.52 29.92 25.52Z"
                  stroke="white"
                  strokeWidth="5.28"
                ></path>
                <path
                  d="M10.56 25.52H18.48V33.44C18.48 37.8141 14.9341 41.36 10.56 41.36C6.1859 41.36 2.64 37.8141 2.64 33.44C2.64 29.0659 6.1859 25.52 10.56 25.52Z"
                  stroke="white"
                  strokeWidth="5.28"
                ></path>
              </svg>
            </div>
            <div className="text">
              <h2>CornerUP</h2>
              <p>Reinventing the corner store</p>
              <a href="https://cornerup.com/">Learn more</a>
            </div>
          </div>
        </div>

        <div className="flex preview">
          <StaticImage
            className="plugin"
            src="../images/prototyper.svg"
            alt=""
          />
        </div>
      </div>

      <div id="dinara" className="app">
        <div className="content">
          <div className="icon">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 40C31.0456 40 39.9999 31.0457 39.9999 20.0001C39.9999 8.9544 31.0456 0.00012207 20 0.00012207C8.95428 0.00012207 0 8.9544 0 20.0001C0 31.0457 8.95428 40 20 40ZM21.9937 33.6065C28.6428 32.6407 33.75 26.9168 33.75 20C33.75 13.0832 28.6428 7.35931 21.9937 6.39355C21.4007 6.30742 21.1042 6.26436 20.7977 6.38336C20.5516 6.47889 20.2917 6.70371 20.1618 6.93351C20 7.21979 20 7.56321 20 8.25006V31.75C20 32.4368 20 32.7803 20.1618 33.0666C20.2917 33.2963 20.5516 33.5212 20.7977 33.6167C21.1042 33.7357 21.4007 33.6926 21.9937 33.6065Z"
                fill="white"
              ></path>
            </svg>
          </div>
          <div className="text">
            <h2>Dinara</h2>
            <p>Commercial bank for crypto</p>
            <a href="https://dinara.com/">Learn more</a>
          </div>
        </div>
      </div>

      <div id="ratio21" className="app">
        <div className="content">
          <div className="icon">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 38.5454C21.2305 38.5454 22.2388 37.6396 22.4097 36.3579C24.1016 24.9419 25.6567 23.3525 36.6284 22.105C37.8931 21.9512 38.8672 20.8916 38.8672 19.6611C38.8672 18.4136 37.9102 17.3882 36.6455 17.2002C25.7422 15.6792 24.3921 14.3462 22.4097 2.94727C22.1875 1.68262 21.2134 0.776855 20 0.776855C18.7524 0.776855 17.7612 1.68262 17.5562 2.96436C15.8984 14.3633 14.3433 15.9526 3.38867 17.2002C2.08984 17.3711 1.13281 18.3965 1.13281 19.6611C1.13281 20.8916 2.05566 21.917 3.35449 22.105C14.2749 23.6602 15.6079 24.9761 17.5562 36.375C17.8125 37.6567 18.8037 38.5454 20 38.5454Z"
                fill="white"
              ></path>
            </svg>
          </div>
          <div className="text">
            <h2>Ratio21</h2>
            <p>Reinventing high school with afterschool</p>
            <a href="https://ratio21.com/">Learn more</a>
          </div>
        </div>
      </div>

      <div id="lifex" className="app">
        <div className="content">
          <div className="icon">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 40C31.0456 40 39.9999 31.0457 39.9999 20.0001C39.9999 8.9544 31.0456 0.00012207 20 0.00012207C8.95428 0.00012207 0 8.9544 0 20.0001C0 31.0457 8.95428 40 20 40ZM21.9937 33.6065C28.6428 32.6407 33.75 26.9168 33.75 20C33.75 13.0832 28.6428 7.35931 21.9937 6.39355C21.4007 6.30742 21.1042 6.26436 20.7977 6.38336C20.5516 6.47889 20.2917 6.70371 20.1618 6.93351C20 7.21979 20 7.56321 20 8.25006V31.75C20 32.4368 20 32.7803 20.1618 33.0666C20.2917 33.2963 20.5516 33.5212 20.7977 33.6167C21.1042 33.7357 21.4007 33.6926 21.9937 33.6065Z"
                fill="#333333"
              ></path>
            </svg>
          </div>
          <div className="text">
            <h2>Life Extension</h2>
            <p>
              Investing in software-driven science companies extending life for
              people and planet
            </p>
            <a href="https://lifex.vc/">Learn more</a>
          </div>
        </div>
      </div>

      <div id="labs" className="app">
        <div className="content">
          <div className="icon">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 40C31.0456 40 39.9999 31.0457 39.9999 20.0001C39.9999 8.9544 31.0456 0.00012207 20 0.00012207C8.95428 0.00012207 0 8.9544 0 20.0001C0 31.0457 8.95428 40 20 40ZM21.9937 33.6065C28.6428 32.6407 33.75 26.9168 33.75 20C33.75 13.0832 28.6428 7.35931 21.9937 6.39355C21.4007 6.30742 21.1042 6.26436 20.7977 6.38336C20.5516 6.47889 20.2917 6.70371 20.1618 6.93351C20 7.21979 20 7.56321 20 8.25006V31.75C20 32.4368 20 32.7803 20.1618 33.0666C20.2917 33.2963 20.5516 33.5212 20.7977 33.6167C21.1042 33.7357 21.4007 33.6926 21.9937 33.6065Z"
                fill="white"
              ></path>
            </svg>
          </div>
          <div className="text">
            <h2>Popular Change Labs</h2>
            <p>What is next?</p>
            <a href="mailto:amol@popularchange.com">Let's brainstorm</a>
          </div>
        </div>
      </div>
    </section>

    <header className="footer inner">
      <ul className="nav">
        <li className="logo">
          <h3>
            <a href="https://popularchange.com/">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10.9969 16.8033C14.3214 16.3204 16.875 13.4584 16.875 10C16.875 6.5416 14.3214 3.67963 10.9969 3.19674C10.7004 3.15368 10.5521 3.13215 10.3988 3.19165C10.2758 3.23941 10.1459 3.35182 10.0809 3.46672C10 3.60986 10 3.78158 10 4.125V15.875C10 16.2184 10 16.3901 10.0809 16.5333C10.1459 16.6482 10.2758 16.7606 10.3988 16.8084C10.5521 16.8679 10.7004 16.8463 10.9969 16.8033Z"
                  fill="#333333"
                ></path>
              </svg>
              <span></span>
            </a>
          </h3>
        </li>
        <li>
          <a href="">TBD About</a>
        </li>
        <li>
          <a href="">TBD Careers</a>
        </li>
        <li>
          <a href="">TBD Blog</a>
        </li>
      </ul>

      <ul>
        <li>
          <a href="">TBD Twitter</a>
        </li>
        <li>
          <a href="">TBD Discord</a>
        </li>
        <li>
          <a href="mailto:amol@popularchange.com">Contact</a>
        </li>
      </ul>
    </header>
  </Layout>
);

export default IndexPage;
